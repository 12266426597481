import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"

// Connects to data-controller="youtube-player"
export default class extends Controller {
  static values = { link: String, configUrl: String, completed: Boolean }

  connect() {
    this.videoId = this.#getVideoIdFromLink();
    this.#loadYoutubePlayer()
      .then(() => {
        this.#initializePlayer();
      })
      .catch((error) => {
        console.error("Erro ao carregar API YouTube", error);
      });
  }

  #initializePlayer() {
    if (typeof YT !== 'undefined' && YT.Player) {
      this.player = new YT.Player(this.element, {
        videoId: this.videoId,
        events: {
          onReady: this.#onReady.bind(this),
          onStateChange: this.#onStateChange.bind(this)
        },
        playerVars: {
          autoplay: 1
        }
      });
    } else {
      console.error("YouTube API failed to load.");
    }
  }

  #onReady(event) {
    event.target.playVideo();
  }

  #onStateChange(event) {
    if (event.data == YT.PlayerState.PLAYING) {
      if (!this.interval) {
        this.interval = setInterval(this.#checkTimeLeft.bind(this), 1000);
      }
    }
  }

  #checkTimeLeft() {
    const duration = this.player.playerInfo.duration;
    const currentTime = this.player.playerInfo.currentTime;
    const timeLeft = duration - currentTime;
    const fivePercentOfDuration = duration * 0.15;

    if (timeLeft <= fivePercentOfDuration) {  
      clearInterval(this.interval);
      this.interval = null;
      this.#markAsCompleted();
    }
  }

  #markAsCompleted() {
    post(this.configUrlValue, {
      headers: {
        Accept: "text/vnd.turbo-stream.html"
      }
    })
    .then(html => Turbo.renderStreamMessage(this.element, html))
    .catch(error => console.error("Erro ao marcar como concluído", error))
  }

  #loadYoutubePlayer() {
    return new Promise((resolve, reject) => {
      if (typeof YT !== 'undefined' && typeof YT.Player !== 'undefined') {
        resolve();
      } else {
        const tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        tag.onload = () => {
          window.onYouTubeIframeAPIReady = () => {
            resolve();
          };
        };
        tag.onerror = (error) => reject(error);

        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      }
    });
  }

  #getVideoIdFromLink() {
    const videoId = this.linkValue.split("v=")[1]?.split("&")[0];
    return videoId;
  }
}
